import * as React from "react"
import "../styles/general.scss"
import { content_pl } from "../content/kamil"
import { content_en } from "../content/kamil"


const MiniWorkImage = ({ image }) => {
  return (
    <div
      style={{
          width: "40px",
        height: "40px",
        float: "left",
        marginRight: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={ image }
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          objectPosition: "center",
        }}
      />
    </div>
  )
}


const SegmentTitle = ({ segment }) => {
  return (
    <div
      className="segment_general"
      >
      <div className="segment_title">
        { segment.title }
      </div>
    </div>
  )
}


const SegmentListNormal = ({ segment, tabs }) => {
  return (
    <div
      style={{
        width: 100 - tabs * 5 + "%",
        paddingLeft: tabs * 5 + "%",
      }}
    >
      <div
        className="segment_content_normal"
      >
          <ul>
          <li>
              {
              segment.content.length > 0 ?
                segment.title + ":"
              :
                segment.title + ","
            }
          </li>
              {
              segment.content.length > 0 ?
                <ul>
                {
                  segment.content.map(
                    (item, index) => {
                      return (
                        <li key={ index }>
                          { item + "," }
                        </li>
                      )
                    }
                  )
                }
                </ul>
              :
                <></>
            }
        </ul>
      </div>
    </div>
  )
}

const SegmentListWork = ({ segment, tabs }) => {
 return (
   <div
     style={{
        width: 100 - tabs * 5 + "%",
     }}
   >
     <div
       style={{
           borderLeft: segment.mainBorderColor === null ? "" : "3.5px dotted " + segment.mainBorderColor,
           paddingLeft: tabs * 2.5 + "%",
           marginLeft: tabs * 2.5 + "%",
           marginTop: segment.firstElement === true ? "15px" : "",
           marginBottom: segment.noElements === true ? "15px" : "",
       }}
       className="segment_content_work"
     >
       {
         segment.image != "" ?
           <MiniWorkImage
             image={ segment.image }
           />
         :
           ""
       }
       <div
         className="segment_content_title"
       >
         {
           segment.content.length > 0 ?
             segment.title + ":"
           :
             segment.lastElement === true ?
                 segment.title + "."
             :
               segment.noElements === true ?
                 segment.title + ","
               :
                 segment.title + ":"
         }
       </div>
         {
           segment.content.length > 0 ?
             <ul
                 style={{
                    borderLeft: segment.branchBorderColor === null ? "" : "3.5px dotted " + segment.branchBorderColor,
                    marginBottom: segment.lastSubElement === true ? "30px" : "",
                    paddingBottom: segment.haveOneElement === true ? "30px" : "",
                 }}
             >
             {
               segment.content.map(
                 (item, index) => {
                   return (
                     <li key={ index }>
                       { item + "," }
                     </li>
                   )
                 }
               )
             }
             </ul>
           :
             <></>
        }
      </div>
    </div>
  )
}


const SegmentCreateBranch = ({ segment, tabs }) => {
  return (
    <div
      style={{
        width: 100 - tabs * 5 + "%",
      }}
    >
      <div
        className="segment_content_work"
        style={{
            color: segment.branchBorderColor,
            fontWeight: "bold",
            marginLeft: tabs * 2.15 + "%",
            marginTop: "-5px",
        }}
      >
        x
      </div>
      <div
        style={{
           borderLeft: "3.5px dotted " + segment.mainBorderColor,
           marginLeft: tabs * 2.5 + "%",
        }}
        className="segment_content_work"
      >
        <div
          style={{
            width: tabs * 2.5 + "%",
            height: "20px",
            marginLeft: "-3.5px",
            borderRadius: "0 0 0 100%",
            borderBottom: "3.5px dotted " + segment.branchBorderColor,
            borderLeft: "3.5px dotted " + segment.branchBorderColor,
          }}
        >
        </div>
        <div
          style={{
            width: "18px",
            height: "20px",
            marginLeft: tabs * 2.5 + "%",
            borderRadius: "0 100% 0 0",
            borderTop: "3.5px dotted " + segment.branchBorderColor,
            borderRight: "3.5px dotted " + segment.branchBorderColor,
          }}
        >
        </div>
      </div>
    </div>
  )
}


const SegmentMergeBranch = ({ segment, tabs }) => {
  return (
    <div
      style={{
        width: 100 - tabs * 5 + "%",
      }}
    >
      <div
        style={{
           borderLeft: "3.5px dotted " + segment.mainBorderColor,
           marginLeft: tabs * 2.5 + "%",
        }}
        className="segment_content_work"
      >
        <div
          style={{
            width: "18px",
            height: "20px",
            marginLeft: tabs * 2.5 + "%",
            borderRadius: "0 0 100% 0",
            borderBottom: "3.5px dotted " + segment.branchBorderColor,
            borderRight: "3.5px dotted " + segment.branchBorderColor,
          }}
        >
        </div>
        <div
          style={{
            width: tabs * 2.5 + "%",
            height: "20px",
            marginLeft: "-3.5px",
            borderRadius: "100% 0 0 0",
            borderTop: "3.5px dotted " + segment.branchBorderColor,
            borderLeft: "3.5px dotted " + segment.branchBorderColor,
          }}
        >
        </div>
      </div>
      <div
        className="segment_content_work"
        style={{
            color: segment.branchBorderColor,
            fontWeight: "bold",
            marginLeft: tabs * 2.15 + "%",
            marginTop: "-5px",
        }}
      >
        x
      </div>
    </div>
  )
}


const SegmentGeneral = ({ segment }) => {
  return (
    <div
      className="segment_general"
      >
      <img
        src={ segment.image }
        className="segment_image cover"
      />
      <div className="segment_title segment_title_image">
        { segment.title }
      </div>
      <div className="segment_content_image">
        {
          typeof segment.content === "object" ?
            Object.keys(segment.content).map( (key) => {
                return (
                  <>
                    <div
                      className="segment_image_item"
                      key={ "item_" + key }
                    >

                      { key + ": " }

                    </div>
                    <div
                      className="segment_image_item subitem"
                    >

                      {
                        segment.content[key].includes("git.") ?
                          <a href={ "https://" + segment.content[key] }>
                            { segment.content[key] }
                          </a>
                        :
                          segment.content[key]
                      }

                    </div>
                  </>
                )
              }
            )
          :
            "lol"
        }
      </div>
    </div>
  )
}


const SegmentRODO = ({ segment }) => {
  return (
    <div
      className="segment_general"
      >
      <div className="segment_content_normal foot_content">
        { segment.content }
      </div>
    </div>
  )
}


const Segment = ({ index, segment }) => {

  let segment_index = "segment_" + index

  return (
    <div
      id={ segment_index }
      key={ segment_index }
    >
      {
        segment.type === "generalTitleSegment" ?
          <>
            <SegmentGeneral
              segment={ segment }
              />
          </>
        :
          segment.type === "titleSegment" ?
            <>
              <SegmentTitle
                segment={ segment }
              />
            </>
          :
            segment.type === "subSegment" ?
              <>
                <SegmentListNormal
                  segment={ segment }
                  tabs={ segment.tabs }
                />
              </>
            :
              segment.type === "workSubSegment" ?
                <>
                  <SegmentListWork
                    segment={ segment }
                    tabs={ segment.tabs }
                  />
                </>
                :
                segment.type === "createBranchSegment" ?
                    <>
                      <SegmentCreateBranch
                        segment={ segment }
                        tabs={ segment.tabs }
                      />
                  </>
                :
                  segment.type === "mergeBranchSegment" ?
                      <>
                        <SegmentMergeBranch
                          segment={ segment }
                          tabs={ segment.tabs }
                        />
                    </>
                  :
                    segment.type === "RODOSegment" ?
                      <>
                        <SegmentRODO
                            segment={ segment }
                        />
                      </>
                    :
                      <></>
      }
    </div>
  )
}


const IndexPage = () => {

  let eng_ = "🇬🇧"
  let pol_ = "🇵🇱"

  const [content_swapper, set_content_swap] = React.useState(pol_);
  const [content, set_content] = React.useState(content_en);

  const swap_content = (event) => {
    event.preventDefault()
    if (content_swapper === eng_) {
      set_content_swap(pol_)
      set_content(content_en)
    } else if (content_swapper === pol_) {
      set_content_swap(eng_)
      set_content(content_pl)
    }
  }

  return (
    <>
      <div className="global_segment">
        <div className="title_bar">
          Curriculum Vitae
        </div>
        <div className="language_bar">
          <div onClick={ e => swap_content(e) }>
            { content_swapper }
          </div>
        </div>
        {
          content.map( (value, index) => {
              return (
                <Segment
                  index={ index }
                  segment={ value }
                />
              )
            }
          )
        }
      </div>
      <div className="foot">

      </div>
    </>
  )
}

export default IndexPage
